import React from "react";

import Navbar from "../../components/navbar";
import Footer from "../components/footer";
import Switcher from "../../components/switcher";

import i18n from "../../i18n";
import { fetchThumbnail } from "../../api/information";
import List from "./list";

export default function TicketList(){
    const [thumbnail, setThumbnail] = React.useState({});

    React.useEffect(()=>{
        fetchThumbnail("Ticket").then((resp)=>{
            setThumbnail(resp.image?.url);
        })
    },[])

    return(
        <>
            <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
            <section 
                className="relative table w-full items-center py-36 bg-top bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${thumbnail})` }}
            >
                <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">{i18n.t("ticket")}</h3>
                    </div>
                </div>
            </section>

            <List travelType="Ticket" actionPath="/ticket" actionDetail="/ticket_detail" />

            <Footer/>
            <Switcher/>
        </>
    )
}