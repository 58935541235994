import React from "react";

import {FiMapPin, FiMail} from '../assets/icons/vander'
import { useSelector } from "react-redux";

export default function Tagline(){
    const {contact} = useSelector((state) => state.information)

    return(
        <>
        <div className="tagline bg-slate-900">
            <div className="container relative">                
                <div className="grid grid-cols-1">
                    <div className="flex items-center justify-between">
                        <ul className="list-none space-x-2">
                            <li className="inline-flex items-center ms-2">
                                <FiMapPin className="text-red-500 size-4"></FiMapPin>
                                <span className="ms-2 text-slate-300">{contact?.address}</span>
                            </li>
                        </ul>

                        <ul className="list-none">
                            <li className="inline-flex items-center">
                                <FiMail className="text-red-500 size-4"></FiMail>
                                <a href={"mailto:"+contact?.email} className="ms-2 text-slate-300 hover:text-slate-200">{contact?.email}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}