import Network from "../helpers/network"

export const fetchContact = async () => {
  const response = await Network.get(`/v1/public/contact`);
  return response.data;
};

export const fetchAbout = async () => {
  const response = await Network.get(`/v1/public/about_us`);
  return response.data;
};

export const fetchReview = async () => {
  const response = await Network.get(`/v1/public/reviews`);
  return response.data;
};

export const fetchBanner = async () => {
  const response = await Network.get(`/v1/public/banner`);
  return response.data;
};

export const fetchMenuAddress = async () => {
  const response = await Network.get(`/v1/public/address_menu`);
  return response.data;
};

export const fetchTeam = async () => {
  const response = await Network.get(`/v1/public/team`);
  return response.data;
};

export const fetchThumbnail = async (thumbnailType) => {
  const response = await Network.get(`/v1/public/thumbnail/${thumbnailType}`);
  return response.data;
};

export const sentMessage = async (body) => {
  const response = await Network.post(`/v1/public/contact_message`, body);
  return response.data;
};

