import { createSlice } from '@reduxjs/toolkit';

const informationSlice = createSlice({
  name: 'information',
  initialState: {
    about: {},
    contact: {}
  }, 
  reducers: {
    SetAbout: (state, action) => {
      state.about = action.payload;
    },
    SetContact: (state, action) => {
        state.contact = action.payload;
    },
  },
});

export const { SetAbout, SetContact } = informationSlice.actions;
export default informationSlice.reducer;
