import React, { useState } from 'react'
import Navbar from "../../components/navbar";
import { Link } from "react-router-dom";

import Blogs from '../components/blogs'
import Client from '../components/client'
import About from '../components/about'
import Footer from '../components/footer';
import Switcher from '../../components/switcher';

import { teamData,placeImage } from "../../data/data";

import {FiFacebook, FiInstagram, FiLinkedin} from "../../assets/icons/vander"

import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

import Lightbox from 'react-18-image-lightbox';
import "react-18-image-lightbox/style.css"
import i18n from '../../i18n';
import { fetchTeam } from '../../api/information';

export default function Aboutus(){
    let [isOpen, setisOpen] = useState(false);
    let [teams, setTeams] = useState([]);
    let [currentImageIndex, setCurrentImageIndex] = useState(0);

    let handleMovePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + placeImage.length - 1) % placeImage.length);
    };

    let handleMoveNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % placeImage.length);
    };
    let currentImage = placeImage[currentImageIndex];

    let handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setisOpen(true);
    };

    React.useEffect(()=>{
        fetchTeam().then((resp) => {
            setTeams(resp);
            console.log(resp);
        })
    },[])

    const settings = {
        container: '.tiny-three-item',
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 12,
        responsive: {
            992: {
                items: 3
            },

            767: {
                items: 2
            },

            320: {
                items: 1
            },
        },
    };
    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>
        <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">Travosy Travel Agency</h3>
                </div>
            </div>
            
            <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul className="tracking-[0.5px] mb-0 inline-block">
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Travosy</Link></li>
                    <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">About Us</li>
                </ul>
            </div>
        </section>

        <section className="relative md:pb-24 pb-16">
            <About/>

            <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">{i18n.t("our_team")}</h3>
                </div>

                <div style={{
                    justifyContent:"center",
                    justifyItems: "center",
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center"
                }} className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px] h-full justify-center text-center">
                    {teams.map((item, index) => {
                        return (
                        <div
                            className="lg:col-span-3 md:col-span-6 flex items-center justify-center h-full"
                            key={index}
                        >
                            {/* Team Member Card */}
                            <div className="group text-center flex flex-col items-center justify-center">
                            <div className="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                                {/* Profile Image */}
                                <img
                                src={item.image?.url}
                                className="h-full w-full object-cover"
                                alt={item.first_name || "Team Member"}
                                />
                                {/* Overlay */}
                                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 duration-500"></div>

                                {/* Social Media Icons */}
                                <ul className="list-none absolute inset-x-0 -bottom-20 group-hover:bottom-5 duration-500 space-x-1">
                                {item.social
                                    ?.filter((data) => data.link !== "")
                                    .map((data) => (
                                    <li key={data.id} className="inline">
                                        <Link
                                        to={data.link}
                                        className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-md border border-red-500 bg-red-500 text-white"
                                        >
                                        <img
                                            className="h-8 w-8 object-cover"
                                            src={data.detail?.image?.url}
                                            alt={data.detail?.name}
                                        />
                                        </Link>
                                    </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Name and Position */}
                            <div className="content mt-3">
                                <Link
                                to=""
                                className="text-lg font-semibold hover:text-red-500 duration-500"
                                >
                                {item.first_name} {item.last_name}
                                </Link>
                                <p className="text-slate-400">{item.position}</p>
                            </div>
                            </div>
                        </div>
                        );
                    })}
                    </div>




            </div>

            <Client/>

            <Blogs/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}