import React, { useState, useEffect, useRef  } from 'react';
import { Link } from "react-router-dom";

import logoDark from "../assets/hpt-dark.png"
import logoWhite from "../assets/hpt-white.png"

import {FiSearch} from '../assets/icons/vander'
import i18n from '../i18n';
import { fetchMenuAddress } from '../api/information';
import Language from './language';
import { fetchCategory } from '../api/travel';

export default function Navbar({navclass, navlight, manuclass}){
    let [scrolling, setScrolling] = useState(false);
    let [isToggle, setToggle] = useState(false);
    let [manu , setManu] = useState('');
    let [subManu , setSubManu] = useState('');
    let [isOpen, setIsOpen] = useState(false);
    let [userManu, setUserManu] = useState(false);

    let [countryMenu , setCountryMenu] = useState([]);
    let [cateCarRental , setCateCarRental] = useState([]);
    let [cateTicket , setCateTicket] = useState([]);

    let dropdownRef = useRef(null);
    let userRef = useRef(null)

    React.useEffect(()=>{
        fetchMenuAddress().then((resp) => {
            setCountryMenu(resp);
        })
    },[])

    React.useEffect(()=>{
        fetchCategory("CarRental").then((resp) => {
            setCateCarRental(resp);
        })
    },[])

    React.useEffect(()=>{
        fetchCategory("Ticket").then((resp) => {
            setCateTicket(resp);
        })
    },[])

    useEffect(()=>{
        const handleScroll = () => {
            const isScrolling = window.scrollY > 50;
            setScrolling(isScrolling);
        };

        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              setIsOpen(false);
            }
          };

        const userOutsideClick = (e) =>{
            if(userRef.current && !userRef.current.contains(e.target)){
                setUserManu(false)
            }
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', handleOutsideClick);
        window.addEventListener('click', userOutsideClick);
        
        let current = window.location.pathname
        setManu(current)
        setSubManu(current)
        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('click', userOutsideClick);
          };
    },[])

    const toggleMenu = () =>{
        setToggle(!isToggle)
      }

    return(
        <nav id="topnav" className={`${navclass} ${scrolling ? 'nav-sticky' : ''}`}>
            <div className="container relative">
                {navlight && (
                    <Link className="logo" to="/">
                        <span className="inline-block dark:hidden">
                            <img src={logoDark} style={{width:32, height: 35}}  className="l-light" alt=""/>
                            <img src={logoDark} style={{width:32, height: 35}}  className="l-dark" alt=""/>
                            &nbsp;<font color="black" >HappyTrip</font>
                        </span>
                        
                        
                        <span className="inline-block hidden dark:inline-block">
                            <img src={logoWhite} style={{width:32, height: 35}} className="hidden dark:inline-block" alt=""/>
                            &nbsp;<font color="white" >HappyTrip</font>
                        </span>
                    </Link>
                )}
                {!navlight && (
                    <Link className="logo" to="/">
                        <div>
                            <img src={logoDark} width={32} className="h-7 inline-block dark:hidden" alt=""/>
                            <img src={logoWhite} width={32} className="h-7 hidden dark:inline-block" alt=""/>
                        </div>
                    </Link>
                )}

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className={`navbar-toggle ${isToggle ? 'open' : ''}`} id="isToggle" onClick={() =>toggleMenu()}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>

                <ul className="buy-button list-none mb-0 space-x-1">
                    <li className="dropdown inline-block relative ps-0.5" ref={userRef}>
                        <Language />
                    </li>
                </ul>

                <div id="navigation" style={{display: isToggle === true ? 'block' : 'none'}}>
                    <ul className={`navigation-menu ${manuclass}`}>
                        <li className={`${manu === '/' ? 'active' : '' }`}><Link to="/" className="sub-menu-item">{i18n.t("home")}</Link></li>

                        <li className={`has-submenu parent-parent-menu-item ${['/tour', countryMenu.map(((item,index) => '/tour'+index)) ].includes(manu) ? 'active' : '' }`}>
                            <Link to="" onClick={()=>setSubManu(subManu === '/tour' ? '' : '/tour' )}>{i18n.t("tour")}</Link>
                            <span className="menu-arrow"></span>
                            <ul className={`submenu ${['/tour'].includes(subManu) ? 'open' : '' }`}>
                                <li><Link to="/tour" className="sub-menu-item">{i18n.t("all")}</Link></li>
                                {
                                    countryMenu.map((item,index) => <li className={`has-submenu parent-menu-item`} key={'country'+index}>
                                        <Link to="" onClick={()=>setSubManu(subManu === '/tour'+index ? '' : '/tour'+index)}>{item.name}</Link><span className="submenu-arrow"></span>
                                        <ul className={`submenu ${['/tour'+index].includes(subManu) ? 'open' : ''}`}>
                                            <li><Link to={`/tour?country_id=${item.id}`} className="sub-menu-item">{i18n.t("all")}</Link></li>
                                            {item?.provinces?.map((subItem, subIndex)=> {
                                                return <li key={'provinces'+subIndex} ><Link to={`/tour?country_id=${item.id}&province_id=${subItem.id}`} className="sub-menu-item">{subItem.name}</Link></li>
                                            })}
                                        </ul>
                                    </li>)
                                }
                            </ul>
                        </li>

                        <li className={`has-submenu parent-parent-menu-item ${['/car_rental', cateCarRental.map(((_,index) => '/tour'+index)) ].includes(manu) ? 'active' : '' }`}>
                            <Link to="" onClick={()=>setSubManu(subManu === '/car_rental' ? '' : '/car_rental' )}>{i18n.t("car_rental")}</Link>
                            <span className="menu-arrow"></span>
                            <ul className={`submenu ${['/car_rental'].includes(subManu) ? 'open' : '' }`}>
                                <li><Link to="/car_rental" className="sub-menu-item">{i18n.t("all")}</Link></li>
                                {
                                    cateCarRental.map(((item) => <li><Link to={"/car_rental?category="+item.id} className="sub-menu-item">{item.name}</Link></li>))
                                }
                            </ul>
                        </li>

                        <li className={`has-submenu parent-parent-menu-item ${['/ticket', cateTicket.map(((_,index) => '/tour'+index)) ].includes(manu) ? 'active' : '' }`}>
                            <Link to="" onClick={()=>setSubManu(subManu === '/car_rental' ? '' : '/ticket' )}>{i18n.t("ticket")}</Link>
                            <span className="menu-arrow"></span>
                            <ul className={`submenu ${['/ticket'].includes(subManu) ? 'open' : '' }`}>
                                <li><Link to="/ticket" className="sub-menu-item">{i18n.t("all")}</Link></li>
                                {
                                    cateTicket.map(((item) => <li><Link to={"/ticket?category="+item.id} className="sub-menu-item">{item.name}</Link></li>))
                                }
                            </ul>
                        </li>

                        <li className={`${manu === '/blogs' ? 'active' : '' }`}><Link to="/blogs" className="sub-menu-item">{i18n.t("blog")}</Link></li>
                        <li className={`${manu === '/aboutus' ? 'active' : '' }`}><Link to="/aboutus" className="sub-menu-item">{i18n.t("aboutus")}</Link></li>
                        <li className={`${manu === '/contact' ? 'active' : '' }`}><Link to="/contact" className="sub-menu-item">{i18n.t("contactus")}</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}