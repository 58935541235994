import { configureStore } from '@reduxjs/toolkit';

import languageReducer from "./features/language"
import informationReducer from "./features/information"

const store = configureStore({
  reducer: {
    language: languageReducer,
    information: informationReducer,
  },
});

export default store;