import React from "react";
import { Link } from "react-router-dom";
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';
import i18n from "../../i18n";
import { fetchDestination } from "../../api/travel";

export default function Destination(){
    const [destinations, setDestinations] = React.useState([]);

    React.useEffect(()=>{
        fetchDestination().then((resp) => {
            setDestinations(resp);
        })
    },[])

    const settings = {
        container: '.tiny-five-item',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 0,
        responsive: {
            1025: {
                items: 5
            },

            992: {
                items: 4
            },

            767: {
                items: 3
            },

            425: {
                items: 1
            },
        },
    };

    return (
        <div className="container relative">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">{i18n.t("destination")}</h3>
            </div>

            <div className="grid grid-cols-1 relative mt-6">
                <div className="tiny-five-item">
                    <TinySlider settings={settings}>
                        {destinations.map((item,index)=>{
                            return(
                                <div className="tiny-slide" key={index}>
                                    <a href={`/tour?country_id=${item.country?.id}&province_id=${item.province?.id}`}>
                                    <div className="group relative overflow-hidden rounded-md shadow dark:shadow-gray-800 m-2">
                                        
                                        <img src={item.image?.url} className="w-full h-72 object-cover scale-125 group-hover:scale-100 duration-500" alt=""/>
                                       
                                        <div className="absolute inset-0 bg-gradient-to-b to-slate-900 from-transparent opacity-0 group-hover:opacity-100 duration-500"></div>
                                        <div className="absolute p-4 bottom-0 start-0">
                                            <Link to="" className="text-lg font-medium text-white hover:text-red-500 duration-500 ease-in-out">{item.province?.name}</Link>
                                            <p className="text-white/70 group-hover:text-white text-sm duration-500">{item.country?.name}</p>
                                        </div>
                                    </div>
                                    </a>
                                </div>
                            )
                        })}
                    </TinySlider>
                </div>
            </div>
            
        </div>
    )
}