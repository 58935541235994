import React, { useState } from 'react';

import Tagline from "../../components/tagline";
import Navbar from "../../components/navbar";
import About from '../components/about';
import Client from '../components/client';
import Blogs from '../components/blogs';
import Footer from '../components/footer';
import Switcher from '../../components/switcher';

import Destination from '../components/destination';
import Travel from '../components/travel';
import i18n from '../../i18n';
import Banner from '../components/banner';
import Search from '../components/search';

export default function Index(){

    return(
        <>
        <Tagline/>
        <Navbar navclass="defaultscroll is-sticky tagline-height" navlight={true} manuclass="justify-end nav-light"/>
        <Banner />

        <div className="container relative -mt-16 z-1">
            <Search/>
        </div>

        <section className="relative md:py-24 py-16 overflow-hidden">
            <Destination/>

            <Travel 
                title={i18n.t("tour")}
                seeMore={i18n.t("see_more_tours")}
                travelType="Tour"
                actionPath="tour_detail"
            />

            <Travel 
                title={i18n.t("car_rental")}
                seeMore={i18n.t("see_more_car_rental")}
                travelType="CarRental"
                actionPath="car_rental_detail"
            />

            <Travel 
                title={i18n.t("ticket")}
                seeMore={i18n.t("see_more_ticket")}
                travelType="Ticket"
                actionPath="ticket_detail"
            />

            <About/>

            <Client/>

            <Blogs/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}