import React from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../components/footer";
import Switcher from "../../components/switcher";

import { packages } from "../../data/data";

import { useLocation } from 'react-router-dom';

import {FiMapPin} from '../../assets/icons/vander'
import i18n from "../../i18n";
import { fetchTravelSearch } from "../../api/travel";

import Pagination from "../components/pagination";

export default function SearchPage(){

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') || 1;
    const search = queryParams.get('search') || "";

    const [travels, setTravels] = React.useState([]);
    const [pagination, setPagination] = React.useState([]);

    React.useEffect(()=>{
        getData(page)
    },[page])

    const getData = (paramPage) => {
        let params = [];
        params.push("search=" + search);
        fetchTravelSearch(
            {
                page: paramPage, 
                limit: 10, 
                search: search
            }).then((resp)=>{
                setTravels(resp.data);
                let pag = resp.pagination;
                setPagination({
                    url: "/search",
                    params: params,
                    page: pag.page,
                    pageSize: pag.page_size,
                    total: pag.total,
                    totalPage: pag.total_page,
                });
        })
    }

    return(
        <>
        <Navbar navclass="defaultscroll is-sticky" navlight={true} manuclass="justify-end nav-light"/>

        <section className="relative table w-full items-center py-36 bg-[url('../../assets/images/bg/cta.jpg')] bg-top bg-no-repeat bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 className="text-4xl leading-normal tracking-wider font-semibold text-white">{i18n.t("search")}</h3>
                </div>
            </div>
        </section>
        <section className="relative md:py-24 py-16">
            <div className="container relative">
                <div className="grid lg:grid-cols-2 grid-cols-1 gap-6"> 
                    {travels.map((item,index)=>{
                        return(
                            <div className="group rounded-md shadow dark:shadow-gray-700" key={index}>
                                <div className="md:flex md:items-center">
                                    <div className="relative overflow-hidden md:shrink-0 md:rounded-md rounded-t-md shadow dark:shadow-gray-700 md:m-3 mx-3 mt-3">
                                        <img src={item.images[0]?.url} className="h-full w-full object-cover md:w-48 md:h-56 scale-125 group-hover:scale-100 duration-500" alt=""/>
                                        {item.tagText && (
                                            <div className="absolute top-0 start-0 p-4">
                                                <span className="bg-red-500 text-white text-[12px] px-2.5 py-1 font-medium rounded-md h-5">{item.tagText}</span>
                                            </div>
                                        )}
                                    </div>
                                    
                                    <div className="p-4 w-full">
                                        <p className="flex items-center text-slate-400 font-medium mb-2">{item.travel_type}</p>
                                        <p className="flex items-center text-slate-400 font-medium mb-2"><FiMapPin className="text-red-500 size-4 me-1">
                                            </FiMapPin> {item.province?.name} / {item.province.name}</p>
                                        <Link to={`/tour-detail-one/${item.id}`} className="text-lg font-medium hover:text-red-500 duration-500 ease-in-out">{item.name}</Link>

                                        <div className="flex items-center mt-2">
                                            <span className="text-slate-400">Rating:</span>
                                            <ul className="text-lg font-medium text-amber-400 list-none ms-2 space-x-1">
                                                {[...Array(5)].map((_, index) => (
                                                    <li className="inline" key={index}>
                                                        <i className={`mdi ${item.rating >= index + 1 ? 'mdi-star' : 'mdi-star-outline'} align-middle`}></i>
                                                    </li>
                                                ))}
                                                <li className="inline text-black dark:text-white text-sm">{item.rating}</li>
                                            </ul>
                                        </div>
                                        
                                        <div className="mt-4 pt-4 flex justify-between items-center border-t border-slate-100 dark:border-gray-800">
                                            <h5 className="text-lg font-medium text-red-500">
                                            {item.travel_type !== "Blog" && (
                                                item.discount > 0 ? (
                                                    <>
                                                    <s className="text-gray-500">{item.price?.toLocaleString()}</s> 
                                                    {item.discount?.toLocaleString()}
                                                    </>
                                                ) : item.price?.toLocaleString()
                                            )}
                                            </h5>
                                            { item.travel_type === "Blog" && (<Link to={/blog-detail/+item.id} className="text-slate-400 hover:text-red-500">{i18n.t("read_more")}<i className="mdi mdi-arrow-right"></i></Link>) }
                                            { item.travel_type === "Ticket" && (<Link to={/ticket_detail/+item.id} className="text-slate-400 hover:text-red-500">{i18n.t("booking_now")}<i className="mdi mdi-arrow-right"></i></Link>) }
                                            { item.travel_type === "Tour" && (<Link to={/tour_detail/+item.id} className="text-slate-400 hover:text-red-500">{i18n.t("booking_now")}<i className="mdi mdi-arrow-right"></i></Link>) }
                                            { item.travel_type === "CarRental" && (<Link to={/car_rental_detail/+item.id} className="text-slate-400 hover:text-red-500">{i18n.t("booking_now")}<i className="mdi mdi-arrow-right"></i></Link>) }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}                   
                </div>

                <Pagination {...pagination} />
            </div>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}