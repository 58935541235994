import React from "react"
import { Link } from "react-router-dom";
import {FiMapPin} from '../../assets/icons/vander';
import { fetchTravel } from "../../api/travel";
import i18n from "../../i18n";

export default function Travel({travelType, title, seeMore, actionPath}){
    const [travels, setTravels] = React.useState([]);

    React.useEffect(()=>{
        fetchTravel(
            {
                page: 1, 
                limit: 6, 
                travelType: travelType, 
                filter:"&show_on_home_page=true&published=true" 
            }).then((resp)=>{
            setTravels(resp.data);
        })
    },[])
    
    return (
        <div className="container relative md:mt-24 mt-16">
            <div className="grid grid-cols-1 pb-8 text-center">
                <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">{title}</h3>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                {travels?.map((item,index)=>{
                    return(
                        <div className="group rounded-md shadow dark:shadow-gray-700" key={index}>
                            <div className="relative overflow-hidden rounded-t-md shadow dark:shadow-gray-700 mx-3 mt-3">
                                <a href={`${actionPath}/${item.id}`}>
                                    <img src={item.images[0]?.url} className="scale-125 group-hover:scale-100 duration-500" alt=""/>
                                </a>
                                {item.tagText && (
                                    <div className="absolute top-0 start-0 p-4">
                                        <span className="bg-red-500 text-white text-[12px] px-2.5 py-1 font-medium rounded-md h-5">{item.tagText}</span>
                                    </div>
                                )}

                                <div className="absolute top-0 end-0 p-4">
                                    <Link to={`${actionPath}/${item.id}`} className="size-8 inline-flex justify-center items-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-full text-slate-100 dark:text-slate-700 focus:text-red-500 dark:focus:text-red-500 hover:text-red-500 dark:hover:text-red-500"><i className="mdi mdi-heart text-[20px] align-middle"></i></Link>
                                </div>
                            </div>

                            <div className="p-4">
                                <p className="flex items-center text-slate-400 font-medium mb-2"><FiMapPin className="text-red-500 size-4 me-1">
                                    </FiMapPin> {item.province?.name} / {item.province.name}
                                </p>
                                <Link to={`${actionPath}/${item.id}`} className="text-lg font-medium hover:text-red-500 duration-500 ease-in-out">{item.name}</Link>

                                <div className="flex items-center mt-2">
                                    <span className="text-slate-400">Rating:</span>
                                    <ul className="text-lg font-medium text-amber-400 list-none ms-2 space-x-1">
                                        {[...Array(5)].map((_, index) => (
                                            <li className="inline" key={index}>
                                                <i className={`mdi ${item.rating >= index + 1 ? 'mdi-star' : 'mdi-star-outline'} align-middle`}></i>
                                            </li>
                                        ))}
                                        <li className="inline text-black dark:text-white text-sm">{item.rating}</li>
                                    </ul>
                                </div>
                                
                                <div className="mt-4 pt-4 flex justify-between items-center border-t border-slate-100 dark:border-gray-800">
                                    <h5 className="text-lg font-medium text-red-500">
                                    {travelType !== "Blog" && (
                                        item.discount > 0 ? (
                                            <>
                                            <s className="text-gray-500">{item.price?.toLocaleString()}</s> 
                                            {item.discount?.toLocaleString()}
                                            </>
                                        ) : (item.price?.toLocaleString())
                                    )}
                                    </h5>

                                    <Link to="" className="text-slate-400 hover:text-red-500">{i18n.t("booking_now")}<i className="mdi mdi-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="mt-6 text-center">
                <Link to="/grid-right-sidebar" className="text-slate-400 hover:text-red-500 inline-block">{seeMore}<i className="mdi mdi-arrow-right align-middle"></i></Link>
            </div>
        </div>
    )
}