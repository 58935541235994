import React from "react";
import { Link } from "react-router-dom";
import {FiChevronRight} from '../../assets/icons/vander'
import i18n from "../../i18n";
import { fetchTravel } from "../../api/travel";

export default function Blogs(){
    const [travels, setTravels] = React.useState([]);
    
    React.useEffect(()=>{
        fetchTravel(
            {
                page: 1, 
                limit: 6, 
                travelType: "Blog", 
                filter:"&published=true" 
            }).then((resp)=>{
            setTravels(resp.data);
        })
    },[])

    return(
            <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">{i18n.t("blog")}</h3>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {travels?.map((item,index)=>{
                        return(
                            <div className="group relative overflow-hidden" key={index}>
                                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                    <a href={"/blog-detail/"+item.id}>
                                        <img src={item.images[0]?.url} className="group-hover:scale-110 group-hover:rotate-3 duration-500" alt=""/>
                                    </a>
                                    <div className="absolute top-0 start-0 p-4 opacity-0 group-hover:opacity-100 duration-500">
                                        <span className="bg-red-500 text-white text-[12px] px-2.5 py-1 font-medium rounded-md h-5">{item.tag}</span>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <div className="flex mb-4">
                                        <span className="flex items-center text-slate-400 text-sm"></span>
                                        <span className="text-slate-400 text-sm ms-3">by <Link to="" className="text-slate-900 dark:text-white hover:text-red-500 dark:hover:text-red-500 font-medium">{item.user?.first_name} {item.user?.last_name}</Link></span>
                                    </div>

                                    <Link to={"/blog-detail/"+item.id} className="text-lg font-medium hover:text-red-500 duration-500 ease-in-out">{item.name}</Link>
                                    <p className="text-slate-400 mt-2">{item.desc}</p>

                                    <div className="mt-3">
                                        <Link to={"/blog-detail/"+item.id} className="hover:text-red-500 inline-flex items-center">{i18n.t("read_more")}<FiChevronRight className="size-4 ms-1"></FiChevronRight></Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
    )
}