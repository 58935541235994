import React from "react";
import { Link } from "react-router-dom";
import { changeLanguage } from '../features/language'
import { useDispatch } from "react-redux";

export default function Language() {

    let dispatch = useDispatch();

    let userRef = React.useRef(null);

    let [userManu, setUserManu] = React.useState(false);
    const userOutsideClick = (e) =>{
        if(userRef.current && !userRef.current.contains(e.target)){
            setUserManu(false)
        }
    }

    React.useEffect(()=>{
        window.addEventListener('click', userOutsideClick);
        return () => {
            window.removeEventListener('click', userOutsideClick);
        };
    },[]);

    const handleLanguageChange = (lang) => {
        dispatch(changeLanguage(lang));
        setUserManu(false);
        window.location.reload() 
    };

    return (
        <li className="dropdown inline-block relative ps-0.5" ref={userRef}>
            <button className="dropdown-toggle items-center" type="button" onClick={()=>setUserManu(!userManu)}>
                <span className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-md border border-red-500 bg-red-500 text-white">
                    <i className="mdi mdi-translate text-[20px] align-middle"></i>
                </span>
            </button>
            {userManu && (
                <div className="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-48 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 ">
                    <ul className="py-2 text-start">
                        <li>
                            <Link 
                                onClick={()=>handleLanguageChange("en")}  
                                className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-red-500 dark:hover:text-white">
                                English
                            </Link>
                        </li>
                        <li>
                            <Link 
                                onClick={()=>handleLanguageChange("th")}  
                                className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-red-500 dark:hover:text-white">
                                ภาษาไทย
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </li>
    )
}