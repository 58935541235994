import Network from "../helpers/network"

export const fetchDestination = async () => {
    const response = await Network.get(`/v1/public/destination`);
    return response.data;
};

export const fetchCategory = async (travelType) => {
    const response = await Network.get(`/v1/public/category?category_type=${travelType}`);
    return response.data;
};

export const fetchTravel = async ({page, limit, travelType, filter}) => {
    const response = await Network.get(`/v1/public/travels?travel_type=${travelType}&page=${page}&page_size=${limit}${filter}`);
    return response;
};

export const fetchTravelByID = async (id) => {
    const response = await Network.get(`/v1/public/travels/${id}`);
    return response.data;
};

export const creatTransection = async (body) => {
    const response = await Network.post(`/v1/public/transection`, body);
    return response.data;
};

export const fetchTravelSearch = async ({page, limit, search}) => {
    const response = await Network.get(`/v1/public/search?search=${search}&page=${page}&page_size=${limit}`);
    return response;
};